import cx from 'classnames';
import { type Components } from 'react-markdown';

import { Column, Grid } from '~/ui/components/grid';
import { type QuickLink } from '~/v1/_types/QuickLink';
import { InlineLink } from '~/v1/components/inlineLink/inlineLink';
import Markdown from '~/v1/components/markdown/markdown';
import { mapQuickLinks } from '~/v1/components/quickLinks/quickLinks.utils';

import styles from './text.module.scss';

interface TextModuleProps {
  children?: React.ReactNode;
  className?: string;
  title?: string | null;
  text?: string | null;
  links?: QuickLink[];
  titleClasses?: string;
  customMarkdownComponents?: Components;
  withDropCap?: boolean;
}

export const TextModule: React.FC<TextModuleProps> = ({
  children,
  className,
  title,
  text,
  links,
  customMarkdownComponents,
  titleClasses,
  withDropCap,
}) => {
  return text || links ? (
    <Grid className={cx(styles.grid, className)}>
      <Column
        sm={5}
        md={children ? 5 : 6}
        lg={5}
        offsetLeft={{
          lg: 1,
        }}
      >
        {title && <h2 className={cx(styles.title, titleClasses)}>{title}</h2>}
        {text && (
          <Markdown
            className={cx(styles.text, {
              [styles.textWithSpacing]: children,
              [styles.textWithDropcap]: withDropCap,
            })}
            customComponents={customMarkdownComponents}
          >
            {text}
          </Markdown>
        )}
        {links &&
          links.map((link, index) => (
            <InlineLink
              {...mapQuickLinks([link])[0]}
              isInline
              className={styles.link}
              key={index}
            />
          ))}
      </Column>

      {children && (
        <Column
          sm={4}
          md={2}
          lg={2}
          offsetLeft={{
            sm: 1,
            md: 0,
            lg: 1,
          }}
          className={styles.childrenWrapper}
        >
          {children}
        </Column>
      )}
    </Grid>
  ) : null;
};
